import Image from "next/image";
import logo from "@public/logo-black.png";
import HeaderSearch from "./header-search";
import LocaleOption from "./locale-option";
import dynamic from "next/dynamic";
import GrayTikTokSvg from "@public/gray-tiktok.svg";
import { Fragment, useState, useEffect } from "react";
import { Transition } from "@headlessui/react";
import { Dialog } from "@headlessui/react";
import {
  ArrowLongRightIcon,
  ArrowRightIcon,
  Bars3Icon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import { useRouter } from "next/router";
import SearchBar from "@components/search/search-bar";
import {
  FaFacebookSquare,
  FaInstagramSquare,
  FaTwitterSquare,
  FaDiscord,
} from "react-icons/fa";
import {
  sharedLinks,
  selectedIndexForHamburger,
  topNavLinks,
  generateDomainPath,
  redirectedBrowserTab,
  SocialMediaIcons,
} from "./shared/link-props";
import Link from "next/link";
import { useWindowWidth } from "@react-hook/window-size";
import { Trans } from "@lingui/macro";
import { useMainBannerContext } from "@lib/mainBannerContext";

const NewsStacksCarousel = dynamic(() => import("./news-stack-carousel"));

interface IHeaderProps {
  showNewsStacks?: boolean;
  isNewsStacksSticky?: boolean;
  setFocusInput?: React.Dispatch<React.SetStateAction<boolean>>;
  focusInput?: boolean;
  hideMagnifyingGlassIcon?: boolean;
}

const Header: React.FunctionComponent<IHeaderProps> = ({
  showNewsStacks = true,
  isNewsStacksSticky,
  setFocusInput,
  focusInput,
  hideMagnifyingGlassIcon,
}) => {
  const router = useRouter();
  const width = useWindowWidth();
  const { locale, pathname, asPath } = router;
  const currentYear = new Date().getFullYear();

  const [isOpen, setIsOpen] = useState(false);

  const [isBannerUnderCarousel, setIsBannerUnderCarousel] = useState(false);
  const showMainBanner = useMainBannerContext();

  useEffect(() => {
    if (showNewsStacks && isNewsStacksSticky && width <= 768) {
      setIsBannerUnderCarousel(true);
    } else {
      setIsBannerUnderCarousel(false);
    }
  }, [width]);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  // To open a new tab if logo is clicked or search input is entered on subdomain pages
  const showNewTab = pathname.startsWith("/_subdomain");

  // To remove last scroll position of home if home logo is clicked
  const clearHomeSession = () => {
    sessionStorage.removeItem(`scrollPos:/`);
  };

  const selectedLocale =
    (locale || router.defaultLocale) === "en" ? "" : locale;

  return (
    <>
      <div className="mb-3 flex flex-col">
        <nav aria-label="Global">
          <div className="mx-auto flex max-w-7xl items-center justify-between px-6 pt-8">
            <div onClick={clearHomeSession}>
              <Link href={generateDomainPath(`/${locale}`)}>
                <a target={showNewTab ? "_blank" : undefined}>
                  <Image
                    src={logo}
                    height={24}
                    width={104}
                    alt="logo"
                    priority
                  />
                </a>
              </Link>
            </div>

            <div className="hidden space-x-6 lg:flex lg:justify-center">
              {topNavLinks.map((link) =>
                link.external ? (
                  <a
                    href={
                      link.withLocale ? link.href + selectedLocale : link.href
                    }
                    key={link.href}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="px-4 py-2 font-sen text-text-medium hover:text-text-dark">
                      {link.emoji} {link.title}
                    </div>
                  </a>
                ) : (
                  <div key={link.href}>
                    {redirectedBrowserTab(
                      link,
                      asPath,
                      showNewTab,
                      locale,
                      "header"
                    )}
                  </div>
                )
              )}
            </div>

            <div className="flex items-center space-x-1 2xs:space-x-8">
              <LocaleOption />

              {/* REMOVE AFTER GE15 */}
              {!hideMagnifyingGlassIcon && (
                <HeaderSearch
                  setFocusInput={setFocusInput}
                  focusInput={focusInput}
                  showNewTab={showNewTab}
                />
              )}

              <div className="flex h-8 w-8 items-center justify-center rounded-lg bg-secondary lg:hidden">
                <Bars3Icon
                  className="h-5 w-5 text-primary2"
                  aria-hidden="true"
                  onClick={openModal}
                />
              </div>

              <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-50" onClose={closeModal}>
                  <div className="fixed inset-0">
                    <div className="h-full">
                      <Transition.Child
                        as={Fragment}
                        enter="transition ease-in-out duration-300 transform"
                        enterFrom="translate-x-full"
                        enterTo="translate-x-"
                        leave="transition ease-in-out duration-300 transform"
                        leaveFrom="translate-x-0"
                        leaveTo="translate-x-full"
                      >
                        <Dialog.Panel className="h-full w-full bg-white p-6 text-left first-letter:overflow-hidden">
                          <XMarkIcon
                            onClick={closeModal}
                            className="ml-auto mr-0 mb-10 h-5 w-5 text-primary2"
                          />

                          <div className="mb-10">
                            <SearchBar />
                          </div>

                          <div>
                            {sharedLinks
                              // only shows prn link on sidebar
                              .filter((link) => !link.href.includes("prn"))
                              .slice(0, selectedIndexForHamburger)
                              .map((link) =>
                                link.external ? (
                                  <a
                                    href={
                                      link.withLocale
                                        ? link.href + selectedLocale
                                        : link.href
                                    }
                                    key={link.href}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <div className="mb-4">
                                      <span className="font-sen text-text-medium">
                                        {link.emoji} {link.title}
                                      </span>
                                    </div>
                                  </a>
                                ) : (
                                  <div
                                    key={link.href}
                                    onClick={
                                      link.href.includes("/news") &&
                                      pathname == "/news"
                                        ? closeModal
                                        : null
                                    }
                                  >
                                    {redirectedBrowserTab(
                                      link,
                                      asPath,
                                      showNewTab,
                                      locale,
                                      "hamburgerMainHeader"
                                    )}
                                  </div>
                                )
                              )}
                          </div>

                          <div className="mb-6">
                            <div
                              className={`mx-auto mt-8 flex items-start justify-start text-text-lightest lg:mt-0 lg:justify-start`}
                            >
                              <SocialMediaIcons tiktokColor="gray" />
                            </div>
                          </div>

                          <div className="mb-6 flex flex-wrap items-center">
                            {sharedLinks
                              .slice(
                                selectedIndexForHamburger + 1,
                                sharedLinks.length
                              )
                              .map((link) =>
                                link.external ? (
                                  <a
                                    key={link.href}
                                    href={
                                      link.withLocale
                                        ? link.href + selectedLocale
                                        : link.href
                                    }
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <p className="mr-4 mb-4 font-sen text-xs text-text-medium">
                                      {link.emoji} {link.title}
                                    </p>
                                  </a>
                                ) : (
                                  <div key={link.href}>
                                    {redirectedBrowserTab(
                                      link,
                                      asPath,
                                      showNewTab,
                                      locale,
                                      "hamburgerBtmHeader"
                                    )}
                                  </div>
                                )
                              )}
                          </div>

                          <p className="font-sen text-xs text-disabled">
                            © Copyright Lumi {currentYear}
                          </p>
                          <p className="mt-2 font-sen text-xs text-disabled">
                            Lumi Global Sdn Bhd (1407575-K)
                          </p>
                        </Dialog.Panel>
                      </Transition.Child>
                    </div>
                  </div>
                </Dialog>
              </Transition>
            </div>
          </div>
        </nav>
      </div>
      {showNewsStacks &&
        (isNewsStacksSticky ? (
          <div className="sticky top-0 z-20 mt-4 bg-white px-4 md:relative">
            <NewsStacksCarousel />
          </div>
        ) : (
          <div className="mx-4 mt-4">
            <NewsStacksCarousel />
          </div>
        ))}
      {showMainBanner && (
        <a href={`/${locale}/topics/budget_2025`}>
          <div
            className={`sticky ${
              isBannerUnderCarousel ? "top-14" : "top-0"
            } z-20 flex items-center justify-center space-x-2 bg-primary p-4`}
          >
            <p className="cursor-pointer font-sen text-white underline">
              <Trans>Which MP are you? 🤔 Take the MPTI quiz</Trans>
            </p>
            <ArrowLongRightIcon className="h-4 w-4 text-white" />
          </div>
        </a>
      )}
    </>
  );
};

export default Header;
